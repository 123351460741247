@use "wildlabs-frontend-core/wl";

.page-contact {
  .page-hero {
    .social-links {
      @include wl.desktop {
        display: none;
      }
    }
  }
}
