@use '../utils/variables';
@use '../utils/mixins';
@use "sass:map";
@use "sass:math";

:root {
  --container-width: 1000px;
}

[data-slider] {
  position: relative;
  --slider-slides-per-view: 3;
  --slider-slides-gap: 30px;
  --slider-speed: 300;
  //--slider-easing: easeInOutQuad;
  //--slider-slides-per-group: 3;
  //--slider-autoplay-delay: 3;
}

[data-slider-slides] {
  display: grid;
  grid-auto-flow: column;
  column-gap: var(--slider-slides-gap, 0.1px);
  // 0.01px helps calculate right amount of slides when no gap variable is provided
  grid-auto-columns: calc( (100% - var(--slider-slides-gap, 0.1px) * ( var(--slider-slides-per-view, 1) - 1) ) / var(--slider-slides-per-view, 1));
  overflow: hidden;

  > div {
    background-color: green;
  }
}

[data-slider-next],
[data-slider-prev] {
  cursor: pointer;
  * {
    pointer-events: none;
  }
}

[data-slider-pagination] {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;

  div {
    cursor: pointer;
    width: 10px;
    height: 10px;
    background-color: green;

    &.is-active-pagination {
      background-color: red;
    }
  }
}

[data-slider-pagination-item] {
  cursor: pointer;
}
