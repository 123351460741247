@use "wildlabs-frontend-core/wl";

:root {

  // colors
  --black: #000000;
  --white: #ffffff;

  // base
  --body-background-color: var(--black);
  --body-font-family: 'Aeonik', sans-serif;
  --body-font-size: #{wl.rem(14)};
  --body-color: var(--white);
  --body-font-weight: 400;
  --body-line-height: 1.2;

  --heading-font-family: 'Cotford Text', sans-serif;

  // forms
  --input-text-height: 42px;
  --input-text-border-color: currentColor;
  --input-text-border-width: 0 0 1px;
  --input-text-radius: 0;

  // layout
  --container-gap: 30px;
  --container-width: calc(100vw - var(--container-gap) * 2);

  @include wl.desktop {
    --container-gap: 60px;
    --container-width: calc(100vw - var(--container-gap) * 2);
  }

  @include wl.widescreen {
  }

  @include wl.fullhd {
  }
}
