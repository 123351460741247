@font-face {
  font-family: 'Aeonic';
  src: url('./assets/Aeonik-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cotford Text';
  src: url('./assets/Cotford Text Light.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cotford Text';
  src: url('./assets/Cotford Text Light Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
