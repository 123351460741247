@use "wildlabs-frontend-core/wl";

.subscribe {
  position: relative;
  width: 100%;
  @include wl.tablet {
    width: 27vw;
  }
  &.is-loading {
    pointer-events: none;
    cursor: wait;
  }
  &__fields {
    position: relative;
  }
  &__input,
  &__cta {
    &,
    &:focus,
    &:active {
      outline: none;
    }
  }
  &__label {
    display: block;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: wl.rem(14);
    letter-spacing: 0.15em;
  }
  &__input {
    max-width: 100%;
    width: 100%;
    color: var(--white);
    background-color: transparent;
    display: inline-flex;
    font-size: wl.rem(16);
    border: 0;
    padding: 0 50px 0 0;
    font-family: var(--heading-font-family);
    border-bottom: 1px solid var(--white);
    font-weight: 400;
    transition: border-color .2s;
    height: 40px;
    font-size: wl.rem(16);
    @include wl.tablet {
      font-size: wl.rem(18);
    }
    @include wl.desktop {
      font-size: wl.rem(22);
    }
    &::placeholder {
      color: var(--white);
      opacity: .7;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-webkit-autofill:hover {
      box-shadow: inset 0 0 0 50px var(--body-background-color);
      background-color: var(--body-background-color);
      color: var(--body-color);
      border-color: var(--body-color);
      -webkit-text-fill-color: var(--body-color);
    }

    &:-webkit-autofill::first-line, {
      font-family: inherit;
    }

    &:-webkit-autofill {
      background-color: var(--body-background-color);
    }
  }
  &__cta {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 45px;
    background: transparent;
    border: 0;
    padding: 8px 0;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  &__message {
    line-height: 1.05;
    &.is-success {
      max-width: 300px;
      height: 40px;
      display: flex;
      align-items: flex-end;
    }
    &.is-error {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      margin-top: 4px;
      @include wl.mobile {
        font-size: wl.rem(13);
        margin-top: 3px;
      }
    }
  }
}
