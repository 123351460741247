@use '../utils/variables';

html {
  font-size: var(--html-font-size);
  text-size-adjust: 100%;

  @if variables.$font-smoothing {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
}

body {
  display: block;
  overflow-x: hidden;
  background-color: var(--body-background-color);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  color: var(--body-color);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}
