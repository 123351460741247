@use "wildlabs-frontend-core/wl";

.hero {
  width: 100%;
  position: relative;
  text-align: center;

  &__title {
    margin-left: auto;
    margin-right: auto;
    letter-spacing: -0.02em;
    font-family: var(--heading-font-family);
    line-height: 1.2;
    @include wl.mobile {
      font-size: wl.rem(32);
      max-width: wl.rem(470);
    }
    @include wl.tablet {
      font-size: wl.rem(44);
      max-width: wl.rem(650);
    }
    @include wl.desktop {
      max-width: wl.rem(720);
      font-size: wl.rem(48);
    }
    @include wl.widescreen {
      max-width: wl.rem(860);
      font-size: wl.rem(60);
    }
  }

  &__line1,
  &__line2 {
    position: absolute;
    @include wl.mobile {
      width: 60%;
    }
    @include wl.tablet {
      width: 230px;
    }
    @include wl.widescreen {
      width: 266px;
    }

    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__line1 {
    left: 55%;
    bottom: 100%;
    @include wl.mobile {
      left: 50%;
      transform: rotate(32deg);
      transform-origin: bottom left;
    }
  }

  &__line2 {
    right: 45%;
    top: 100%;
    @include wl.mobile {
      right: 50%;
      transform: rotate(32deg);
      transform-origin: top right;
    }
  }
}
