.bg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //z-index: 99999;

  .swiper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      object-fit: cover;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}
