@use "variables";
@use "sass:map";
@use "sass:list";

//
// Breakpoints
//
@mixin tablet {
  @media (min-width: #{variables.$tablet}) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: #{variables.$tablet}) and (max-width: #{variables.$desktop - 1}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{variables.$desktop}) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: #{variables.$desktop}) and (max-width: #{variables.$widescreen - 1}) {
    @content;
  }
}

@mixin widescreen {
  @media (min-width: #{variables.$widescreen}) {
    @content;
  }
}

@mixin widescreen-only {
  @media (min-width: #{variables.$widescreen}) and (max-width: #{variables.$fullhd - 1}) {
    @content;
  }
}

@mixin fullhd {
  @media (min-width: #{variables.$fullhd}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{variables.$tablet - 1}) {
    @content;
  }
}

@mixin touch {
  @media (max-width: #{variables.$desktop - 1}) {
    @content;
  }
}

@mixin tiny {
  @media (max-width: #{variables.$tiny - 1}) {
    @content;
  }
}

@mixin tiny-up {
  @media (max-width: #{variables.$tiny}) {
    @content;
  }
}

@mixin until-widescreen {
  @media (max-width: #{variables.$widescreen - 1}) {
    @content;
  }
}

@mixin until-fullhd {
  @media (max-width: #{variables.$fullhd - 1}) {
    @content;
  }
}

// any devices support the hover feature
@mixin hover-is-supported {
  @media (hover: hover) {
    @content;
  }
}

// for all touch devices
@mixin hover-is-unsupported {
  @media (hover: none) {
    @content;
  }
}

@mixin from($breakpoint) {
  @media (min-width: #{$breakpoint}) {
    @content;
  }
}

@mixin until($breakpoint) {
  @media (max-width: #{$breakpoint}) {
    @content;
  }
}

//
// Layouts
//

@mixin columns {
  display: grid;
  grid-template-columns: repeat(var(--columns-amount, 12), 1fr);
  gap: var(--columns-gap, 1rem);

  // default mobile column width is full width
  > * {
    grid-column: span var(--columns-amount, 12);
  }
}

@mixin grid-column($from: 0, $to: false) {
  @if ($to) {
    grid-column: #{$from} / #{$to + 1};
  } @else {
    grid-column: span #{$from};
  }
}

@mixin column($from: 0, $to: false, $breakpoint-name: false) {
  @if ($breakpoint-name) {
    @media (min-width: #{map.get(variables.$columns-breakpoints, $breakpoint-name)}) {
      @include grid-column($from, $to);
    }
  } @else {
    @include grid-column($from, $to);
  }
}

@mixin build-columns($name: "") {
  @for $i from 1 through 12 {
    .is-#{$i}#{$name} {
      @include grid-column($i);
    }

    @for $n from 3 through 12 {
      @if $i > 1 {
        @if $n >= $i {
          .is-#{$i}-#{$n}#{$name} {
            @include grid-column($i, $n);
          }
        }
      }
    }
  }
}

// properties are written in a sass:list. ex. $mobile: (1fr 2fr, 20px) where first element is template-columns second is a gap
@mixin grid($mobile: false, $tablet: false, $desktop: false, $widescreen: false, $fullhd: false) {
  display: grid;

  @if $mobile {
    @if list.nth($mobile, 1) {
      grid-template-columns: list.nth($mobile, 1);
    }
    @if list.nth($mobile, 2) {
      gap: list.nth($mobile, 2);
    }
  }

  @if $tablet {
    @media (min-width: #{map.get(variables.$columns-breakpoints, "tablet")}) {
      @if list.nth($tablet, 1) {
        grid-template-columns: list.nth($tablet, 1);
      }
      @if list.nth($tablet, 2) {
        gap: list.nth($tablet, 2);
      }
    }
  }

  @if $desktop {
    @media (min-width: #{map.get(variables.$columns-breakpoints, "desktop")}) {
      @if list.nth($desktop, 1) {
        grid-template-columns: list.nth($desktop, 1);
      }
      @if list.nth($desktop, 2) {
        gap: list.nth($desktop, 2);
      }
    }
  }

  @if $widescreen {
    @media (min-width: #{map.get(variables.$columns-breakpoints, "widescreen")}) {
      @if list.nth($widescreen, 1) {
        grid-template-columns: list.nth($widescreen, 1);
      }
      @if list.nth($widescreen, 2) {
        gap: list.nth($widescreen, 2);
      }
    }
  }

  @if $fullhd {
    @media (min-width: #{map.get(variables.$columns-breakpoints, "fullhd")}) {
      @if list.nth($fullhd, 1) {
        grid-template-columns: list.nth($fullhd, 1);
      }
      @if list.nth($fullhd, 2) {
        gap: list.nth($fullhd, 2);
      }
    }
  }
}

//
// Elements
//
@mixin border-link() {
  position: relative;
  color: currentColor;
  white-space: nowrap;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: var(--border-link-bottom, 0);
    height: var(--border-link-height, 1px);
    width: 100%;
    background-color: currentColor;
    left: 0;
    right: 0;
    margin: auto;
    transform: scaleX(0);
    opacity: 0;
    transform-origin: right;
    transition: transform variables.$speed, opacity variables.$speed-slow;
    pointer-events: none;
  }

  &:hover {
    color: currentColor;

    &::after {
      opacity: 1;
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  &.is-active {
    &::after {
      opacity: 1;
      transform: scaleX(1);
    }

    &:hover {
      &::after {
        transform-origin: right;
        transform: scaleX(0);
        opacity: 0;
      }
    }
  }
}

@mixin active-border-link() {
  @include border-link;

  &::after {
    opacity: 1;
    transform: scaleX(1);
  }

  &:hover {
    &::after {
      transform-origin: right;
      transform: scaleX(0);
      opacity: 0;
    }
  }
}

//
// Transitions
//
@mixin transition($property: all) {
  transition: $property variables.$speed variables.$easing;
}

@mixin transition-slow($property: all) {
  transition: $property variables.$speed-slow variables.$easing;
}

//
// Visual
//
@mixin visually-hidden() {
  position: absolute !important;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin visually-shown() {
  position: inherit !important;
  overflow: auto;
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
}

@mixin responsive-mode($ratio: 16 / 9) {
  position: relative;
  overflow: hidden;
  padding-top: $ratio * 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@mixin fill-out() {
  position: absolute;
  inset: 0;
}

@mixin object-fit($size: cover, $position: center) {
  width: 100%;
  height: 100%;
  object-fit: $size;
  object-position: $position;
}

@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin overlay($offset: 0, $z-index: false, $position: absolute) {
  inset: $offset;
  position: $position;
  width: 100%;
  height: 100%;

  @if ($z-index) {
    z-index: $z-index;
  }
}

@mixin cover($offset: 0, $z-index: false, $position: absolute) {
  @include overlay($offset, $z-index, $position);
  object-fit: cover;
}

@mixin span-horizontally() {
  margin-left: calc((100vw - var(--container-width)) / 2 * #{-1});
  width: 100vw;
}

@mixin span-to-right() {
  margin-right: calc((100vw - var(--container-width)) / 2 * #{-1});
  width: auto;
}

@mixin span-to-left() {
  margin-left: calc((100vw - var(--container-width)) / 2 * #{-1});
}

@mixin no-outline {
  &,
  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
}

@mixin button-reset {
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  user-select: none;
  @include no-outline;

  * {
    pointer-events: none;
  }
}

@mixin input-reset {
  padding: 0;
  border: 0;
  margin: 0;
  background: transparent;
  color: inherit;
  border-radius: 0;
  @include no-outline;

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
}

@mixin hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin crapie {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}
