@use '~wildlabs-frontend-core/wl' with (
    $use-columns: false,
    $use-border-link: false,
    $use-accordion: false,
    $use-modal: false,
);

@forward "styles/base/_index";
@forward "styles/layout/_index";
@forward "styles/libs/_index";
@forward "styles/pages/_index";
@forward "styles/elements/_index";
