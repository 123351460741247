body,
html {
  height: 100%;
}

.wrap {
  position: relative;
  z-index: 20;
  overflow: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
