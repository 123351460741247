@use "wildlabs-frontend-core/wl";
@use "sass:map";

.header {
  flex: 0 0 auto;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 45px;
    padding-bottom: 45px;
  }
  &__logo {
    display: block;
    width: 111px;
    @include wl.tablet {
      width: 135px;
    }
    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
