@use "wildlabs-frontend-core/wl";


.footer {
  flex: 0 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  @include wl.tablet {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @include wl.desktop {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  &__inner {
    @include wl.tablet {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
  &__socials {
    line-height: 1;
    font-family: var(--heading-font-family);
    display: flex;
    align-items: center;
    @include wl.mobile {
      margin-top: 30px;
      justify-content: center;
      font-size: wl.rem(16);
    }
    @include wl.tablet {
      justify-content: flex-end;
      font-size: wl.rem(18);
    }
    @include wl.desktop {
      font-size: wl.rem(22);
    }
    color: var(--white);
    span {
      margin-left: 12px;
      margin-right: 12px;
      pointer-events: none;
    }
    a {
      color: var(--white);
      text-decoration: none;
      position: relative;
      &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -4px;
        opacity: 0;
        @include wl.transition;
        height: 1px;
        background: var(--white);
        content: "";
      }
      &:hover {
        color: var(--white);
        text-decoration: none;
        &:after {
          opacity: .7;
        }
      }
    }
  }
}
