.main {
  flex: 1;
  display: flex;
  &__inner {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
